<mat-drawer-container class="container pl-sm-20" [hasBackdrop]="true">
  <div class="roam-layout"
       [class.expand]="isExpand || !showSidebar"
  >
    <div class="roam-layout-content">
      <ng-content select="[mainContent]"></ng-content>
    </div>
    <div class="roam-layout-right position-relative" *ngIf="showSidebar">
      <div class="nav-show"
           (click)="expandMenuAction()">
        {{ isExpand ? '<' : '>' }}
      </div>
      <div class="roam-layout-right-sidebar">
        <div class="mt-6">
          <div class="black tc-grey-dark mb-16 font-12px fw-600">Quick Action</div>
          <ng-content select="[quickAction]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>

<div class="mobile-view">
  <ng-content select="[bottomMenu]"></ng-content>
</div>
