import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  standalone: true,
  imports: [CommonModule, MatSidenavModule],
  selector: 'app-roam-layout-sidebar',
  templateUrl: './roam-layout-sidebar.component.html',
  styleUrls: ['./roam-layout-sidebar.component.scss']
})
export class RoamLayoutSidebarComponent implements OnInit {

  @Input()
  public showSidebar: boolean | null = true;

  public isExpand: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public expandMenuAction(): void {
    this.isExpand = !this.isExpand
  }

}
