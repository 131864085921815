import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule, Location } from '@angular/common';
import { RoamLoaderModule } from "../../roam-loader/roam-loader.module";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  imports: [CommonModule, RoamLoaderModule, InlineSVGModule],
  selector: 'app-roam-layout-wrapper',
  templateUrl: './roam-layout-wrapper.component.html',
  styleUrls: ['./roam-layout-wrapper.component.scss']
})
export class RoamLayoutWrapperComponent {

  @Input()
  public title: string | null = '';

  @Input()
  public backRoute: boolean = false;

  @Input()
  public setRoute: boolean = false;

  @Input()
  public standalone: boolean = false;

  @Input()
  public isLoader: boolean = true;

  @Input()
  public class: string = '';

  @Input()
  public breadcrumb: boolean = false;

  @Output()
  public onBack: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(private location: Location) { }

  public back(): void {
    if(this.setRoute) {
      this.onBack.emit()
    } else {
      this.location.back();
    }
  }

}
