import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  standalone: true,
  selector: 'app-quick-action-text',
  template: `
    <div class="c-pointer tc-primary font-14px mb-10 text-underline" (click)="actionClick()">
      <img class="mr-6" [src]="icon.plusRed.src" [alt]="icon.plusRed.alt">
      {{ name }}
    </div>
  `,
})
export class QuickActionTextComponent implements OnInit {

  @Input()
  public name: string = '';

  @Input()
  public path: string = '';

  @Input()
  public id: string = '';

  @Output()
  quickActionClick = new EventEmitter<string>();

  public icon = iconLib;


  actionClick(): void {
    this.quickActionClick.emit(this.id);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
