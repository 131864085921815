<div class="roam-wrapper" [class.standalone]="standalone"
     [class]="class"
>
  <header class="justify-between">
    <div class="align-center">
      <ng-content select="[breadcrumb]"></ng-content>
      <ng-container *ngIf="!breadcrumb">
        @if(!isLoader) {
          <div class="icon c-pointer d-flex" *ngIf="backRoute && title" (click)="back()">
            <span [inlineSVG]="'assets/svg/ic-back.svg'"></span>
          </div>
          <h1 class="title" *ngIf="(title); else loader">
            {{ title }}
          </h1>
        } @else {
          <app-loader-dot [size]="5"></app-loader-dot>
        }
      </ng-container>
    </div>
    <div>
      <ng-content select="[actRef]"></ng-content>
    </div>
  </header>
  <div class="roam-content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #loader>
  <ng-container *ngIf="isLoader">
    <app-loader-dot [size]="5"></app-loader-dot>
  </ng-container>
</ng-template>
